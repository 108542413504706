import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { tucarBlue, realWhite } from 'Themes';

export const StandardButtonStyle = styled(Button)({
  fontFamily: 'Poppins,sans-serif',
  fontWeight: 400,
  backgroundColor: realWhite,
  color: tucarBlue,
  transition: 'all 150ms ease',
  cursor: 'pointer',
  border: `2px solid ${tucarBlue}`,
  borderRadius: '25px',
  lineHeight: '100%',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: tucarBlue,
    fontFamily: 'PoppinsSemiBold',
    color: realWhite,
  },
  '&:disabled': {
    cursor: 'not-allowed',
    pointerEvents: 'auto',
    color: tucarBlue,
    fontFamily: 'PoppinsRegular',
    opacity: 0.3,
  },
  '&:disabled:hover': {
    backgroundColor: tucarBlue,
    opacity: 0.3,
    color: realWhite,
    fontFamily: 'PoppinsRegular',
    textTransform: 'none',
  },
});

export const StaticStandardButtonStyle = styled('button')({
  fontFamily: 'Poppins, sans-serif',
  fontWeight: 400,
  backgroundColor: realWhite,
  color: tucarBlue,
  transition: 'all 150ms ease',
  cursor: 'pointer',
  border: `2px solid ${tucarBlue}`,
  borderRadius: '25px',
  lineHeight: '100%',
  textTransform: 'none',
  '&:disabled': {
    cursor: 'not-allowed',
    pointerEvents: 'auto',
    color: tucarBlue,
    fontFamily: 'PoppinsRegular',
    opacity: 0.3,
  },
});
