import axios from 'axios';

const methodMap = {
  GET: 'get',
  POST: 'post',
  PUT: 'put',
  DELETE: 'delete',
  PATCH: 'patch',
};

const getRequestFunction = (requestType) => {
  return methodMap[requestType] || 'get';
};

const requestHandler = async (method, url, body = null, config = {}) => {
  const axiosConfig = {
    ...config,
    method,
    url,
    data: body,
  };
  return axios(axiosConfig);
};

const makeRequest = async (url, requestConfig) => {
  const {
    requestType = 'GET',
    params = {},
    body = null,
    headers = {},
    onSuccess = () => {},
    onError = (err) => {
      throw new Error(err?.message);
    },
  } = requestConfig || {};
  try {
    const method = getRequestFunction(requestType);
    const response = await requestHandler(method, url, body, { params, headers });
    onSuccess(response);
    return response;
  } catch (error) {
    onError(error);
  }
  return null;
};

export default makeRequest;
