import { ApolloClient, InMemoryCache, HttpLink, from, concat } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import * as Sentry from '@sentry/react';
import { getRefreshToken as fetchRefreshToken, getTokenMetadata } from 'Api/auth';
import {
  getRefreshToken,
  isExpired,
  setLoading,
  logout,
  getAuthStore,
  setExpirationDate,
  setAuthStore,
  setUserId,
} from 'Graphql/store';

const httpLink = new HttpLink({ uri: process.env.API_GATEWAY });

const handleSuccessfulRefresh = ({ data }) => {
  setAuthStore(data);
  setLoading(false);
};

const handleError = () => {
  logout();
  setLoading(false);
};

const refreshQuery = async (currentRefreshToken) => {
  return fetchRefreshToken(
    { refresh_token: currentRefreshToken },
    { onSuccess: handleSuccessfulRefresh, onError: handleError },
  );
};

const buildErrorScope = (scope, operation, response) => {
  if (operation) {
    scope.setTag('kind', operation?.operationName);
    scope.setExtra('query', operation?.query?.loc?.source);
    scope.setExtra('variables', operation?.variables);
  }
  scope.setExtra('response', response?.data);
};

const getAccessToken = async (currentRefreshToken, errorOrigin) => {
  const authStoreData = getAuthStore();
  if ((currentRefreshToken && isExpired()) || errorOrigin) {
    try {
      setExpirationDate();
      const { data: refreshQueryData } = (await refreshQuery(currentRefreshToken)) || {};
      const { access_token: accessToken } = refreshQueryData || {};
      if (!authStoreData?.userId)
        await getTokenMetadata(accessToken, { onSuccess: ({ data }) => setUserId(data?.user_id) });
      return { accessToken: accessToken || false };
    } catch (error) {
      logout();
      setLoading(false);
      return { accessToken: false };
    }
  }
  setLoading(false);
  return authStoreData;
};

const authMiddleware = setContext(async (_, { headers }) => {
  const currentRefreshToken = getRefreshToken();
  const { accessToken } = await getAccessToken(currentRefreshToken);
  return {
    headers: {
      ...headers,
      authorization: `Bearer ${accessToken}`,
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError, operation, response, forward }) => {
  if (graphQLErrors) {
    const { headers: oldHeaders, ignoreError, ignorePattern } = operation.getContext();
    graphQLErrors.forEach(async ({ extensions, message, locations, path }) => {
      if (extensions.code === 'UNAUTHENTICATED') {
        const { accessToken } = await getAccessToken(getRefreshToken(), true);
        operation.setContext({
          headers: {
            ...oldHeaders,
            authorization: `Bearer ${accessToken}`,
          },
        });
        return forward(operation);
      }
      if (ignoreError) return null;
      if (ignorePattern) {
        const { pattern = [], mandatory = true } = ignorePattern || {};
        if (mandatory && pattern.every((term) => message?.includes(term))) return null;
        if (!mandatory && pattern.some((term) => message?.includes(term))) return null;
      }
      return Sentry.withScope((scope) => {
        buildErrorScope(scope, operation, response);
        Sentry.captureMessage(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
        );
      });
    });
  }
  if (networkError) {
    Sentry.withScope((scope) => {
      buildErrorScope(scope, operation, response);
      Sentry.captureException(`[Network error]: ${networkError}`);
    });
    Sentry.captureException(`[Network error]: ${networkError}`);
  }
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: from([errorLink, concat(authMiddleware, httpLink)]),
});

export default client;
