import React from 'react';
import PropTypes from 'prop-types';

import { Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert ref={ref} variant="filled" {...props} />;
});

const SuccessAlert = ({ content, handleClose, open, origin }) => (
  <Snackbar anchorOrigin={origin} open={open} onClose={handleClose}>
    <Alert severity="success">{content}</Alert>
  </Snackbar>
);

SuccessAlert.propTypes = {
  content: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  origin: PropTypes.shape({
    vertical: PropTypes.string,
    horizontal: PropTypes.string,
  }),
};

SuccessAlert.defaultProps = {
  origin: { vertical: 'top', horizontal: 'right' },
};

export default SuccessAlert;
