import React from 'react';
import PropTypes from 'prop-types';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

import { setError } from 'Graphql/store';
import { realBlack, realWhite, tucarBlue, tucarRed, tucarWhite } from 'Themes';
import StandardButton from './StandardButton';

const ErrorModal = ({ content, open, onClose }) => {
  const handleClose = () => {
    setError(false);
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="error-dialog"
      aria-describedby="probabbly-a-server-side-error-happened"
    >
      <DialogTitle
        className="PoppinsSemiBold"
        style={{ backgroundColor: tucarRed, color: realWhite }}
        id="alert-dialog-title"
      >
        Ha ocurrido un error
      </DialogTitle>
      <DialogContent
        className="PoppinsRegular"
        style={{ backgroundColor: tucarWhite, padding: '15px 20px' }}
      >
        <DialogContentText id="alert-dialog-description" style={{ color: realBlack }}>
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ backgroundColor: tucarWhite }}>
        <StandardButton
          className="PoppinsRegular"
          style={{ color: tucarBlue }}
          onClick={handleClose}
        >
          Cerrar
        </StandardButton>
      </DialogActions>
    </Dialog>
  );
};

ErrorModal.propTypes = {
  content: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
};

ErrorModal.defaultProps = {
  content:
    'Lo sentimos, pero ha ocurrido un problema inesperado, le recomendamos esperar un poco antes de volver a realizar la acción solicitada',
  open: true,
};

export default ErrorModal;
