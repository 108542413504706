import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Box, Loader, Typography } from '@tucar/pit-stop';
import { tucarBlue } from 'Themes';

const LoadingScreen = ({ fullScreen, size, textOrientation, fontSize }) => {
  const sizePx = (sizeString) => {
    switch (sizeString) {
      case 'big':
        return { size: 300, pointRadio: '18px' };
      case 'medium':
        return { size: 100, pointRadio: '6px' };
      case 'small':
        return { size: 16, pointRadio: '4px' };
      default:
        return { size: 50, pointRadio: '4px' };
    }
  };

  const { size: loaderSize, pointRadio } = useMemo(() => sizePx(size), [size]);

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: ['top', 'bottom'].includes(textOrientation) ? 'column' : 'row',
        justifyContent: ['top', 'bottom'].includes(textOrientation) ? 'center' : 'auto',
        alignItems: 'center',
        flex: 1,
        height: fullScreen ? '100vh' : '100%',
        gap: '5px',
      }}
    >
      {['top', 'left'].includes(textOrientation) && (
        <Typography type="semi-bold" style={{ color: tucarBlue, fontSize }}>
          ...Cargando...
        </Typography>
      )}
      <Loader size={loaderSize} pointRadio={pointRadio} />
      {['bottom', 'right'].includes(textOrientation) && (
        <Typography type="semi-bold" style={{ color: tucarBlue, fontSize }}>
          ...Cargando...
        </Typography>
      )}
    </Box>
  );
};

LoadingScreen.propTypes = {
  fontSize: PropTypes.string,
  fullScreen: PropTypes.bool,
  size: PropTypes.string,
  textOrientation: PropTypes.string,
};

LoadingScreen.defaultProps = {
  fontSize: '1.5rem',
  fullScreen: false,
  size: 'medium',
  textOrientation: 'bottom',
};

export default LoadingScreen;
