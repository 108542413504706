import React, { Suspense, lazy } from 'react';
import { Navigate, Routes, Route, useLocation } from 'react-router-dom';
import LoadingScreen from 'Components/ui/LoadingScreen';
import AuthGuard from 'Components/AuthGuard';

const NotFound = lazy(() => import('Components/NotFound'));
const BasePage = lazy(() => import('./pages'));
const SignIn = lazy(() => import('./pages/auth/SignIn'));
const Settings = lazy(() => import('./pages/Settings'));
const Dashboard = lazy(() => import('./pages/general/Dashboard'));
const LayOut = lazy(() => import('./layouts/app'));

const Router = () => {
  const location = useLocation();
  return (
    <Suspense fallback={<LoadingScreen fullScreen size="big" />}>
      <Routes location={location}>
        <Route path="404" element={<NotFound />} />
        <Route index element={<BasePage />} />
        <Route path="/auth" element={<SignIn />} />
        <Route path="/app/*" element={<AuthGuard />}>
          <Route element={<LayOut />}>
            <Route path="general/dashboard" element={<Dashboard />} />
            <Route path="settings" element={<Settings />} />
            <Route path="*" element={<Navigate to="/404" replace />} />
          </Route>
        </Route>
        <Route path="*" element={<Navigate to="/404" replace />} />
      </Routes>
    </Suspense>
  );
};

export default Router;
