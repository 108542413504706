import React from 'react';
import { ApolloProvider } from '@apollo/client';
import { StylesProvider } from '@mui/styles';
import { Box } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { ThemeProvider as TucarThemeProvider } from '@tucar/pit-stop';

import ErrorFallback from 'Components/ErrorFallback';
import client from 'Contexts/graphql';
import AuthContext from 'Contexts/AuthContext';
import { AlertsContextProvider } from 'Contexts/AlertsContext';
import tucar from 'Themes/Tucar';
import Router from './routes';
import 'Themes/fonts.css';

if (process.env.MODE !== 'development') {
  Sentry.init({
    environment: process.env.MODE,
    dsn: process.env.SENTRY_DNS,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
    normalizeDepth: 10,
    maxValueLength: 400,
  });
}

const content =
  process.env.MODE !== 'development' ? (
    <Sentry.ErrorBoundary fallback={ErrorFallback}>
      <Box sx={{ backgroundColor: 'primary.backgroundApp' }}>
        <AuthContext>
          <Router />
        </AuthContext>
      </Box>
    </Sentry.ErrorBoundary>
  ) : (
    <Box sx={{ backgroundColor: 'primary.backgroundApp' }}>
      <AuthContext>
        <Router />
      </AuthContext>
    </Box>
  );

const App = () => {
  return (
    <ApolloProvider client={client}>
      <StylesProvider injectFirst>
        <TucarThemeProvider>
          <ThemeProvider theme={tucar}>
            <AlertsContextProvider>{content}</AlertsContextProvider>
          </ThemeProvider>
        </TucarThemeProvider>
      </StylesProvider>
    </ApolloProvider>
  );
};

export default App;
