import { tucarBackground, tucarBlue, tucarGrey, tucarRed } from './TucarColors';

const tucarPalette = {
  primary: {
    main: tucarBlue,
    dark: tucarBlue,
    grey: tucarGrey,
    error: tucarRed,
    backgroundApp: tucarBackground,
  },
};

export default tucarPalette;
