import React from 'react';
import PropTypes from 'prop-types';

import { Box, BoxFlex, Typography } from '@tucar/pit-stop';

import WhiteLogo from 'Components/logo/WhiteLogo';
import StandardButton from 'Components/ui/StandardButton';
import { tucarBlue, tucarWhite } from 'Themes';

const ErrorFallback = ({ resetError }) => {
  return (
    <Box style={{ height: '100vh', margin: '0px', backgroundColor: tucarBlue }}>
      <BoxFlex style={{ height: '100vh', alignItems: 'center', justifyContent: 'center' }}>
        <BoxFlex style={{ flexDirection: 'column', gap: '25px' }}>
          <WhiteLogo height="100px" />
          <Typography weight="semi-bold" fontSize={48} color={tucarWhite}>
            Ha ocurrido un error
          </Typography>
          <Typography color={tucarWhite}>
            Lo sentimos, ha ocurrido un error inesperado, por favor comunicarlo al equipo de soporte
          </Typography>
          <StandardButton onClick={() => resetError()} width="100%" fontSize="18px" isStatic>
            Volver a la página anterior
          </StandardButton>
        </BoxFlex>
      </BoxFlex>
    </Box>
  );
};

ErrorFallback.propTypes = {
  resetError: PropTypes.func.isRequired,
};

export default ErrorFallback;
