const buildUri = () => {
  if (process.env.MODE !== 'development') return process.env.OWNER_APP_URL;
  return 'http://localhost:8000';
};

const baseParams = {
  client_id: 'i1MY4uISDtZlyQywXo7SkWtTDtv1JMGQl_aPTSUD7Nk',
  redirect_uri: `${buildUri()}/#/auth`,
};

export default baseParams;
