import { makeRequest } from 'Utils/requestHelpers';
import baseParams from './config';

const getRefreshToken = async (body, { onError, onSuccess }) => {
  return makeRequest(`${process.env.ACCOUNT_SERVICE_URL}/api/v1/oauth/token`, {
    body: { ...body, ...baseParams, grant_type: 'refresh_token' },
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    requestType: 'POST',
    onSuccess,
    onError,
  });
};

export default getRefreshToken;
