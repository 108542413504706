import React from 'react';
import PropTypes from 'prop-types';

import { StandardButtonStyle, StaticStandardButtonStyle } from './styles';

const StandardButton = ({
  children,
  onClick,
  height,
  width,
  fontSize,
  type,
  disabled,
  isStatic,
  style,
  href,
  target,
  ...props
}) => {
  const currentOnClick = (event) => {
    if (href) window.open(href, target || '_blank', 'noopener,noreferrer');
    onClick(event);
  };

  const Component = isStatic ? StaticStandardButtonStyle : StandardButtonStyle;

  return (
    <Component
      style={{ ...style, height, width, fontSize, lineHeight: '100%' }}
      type={type}
      disabled={disabled}
      onClick={currentOnClick}
      {...props}
    >
      {children}
    </Component>
  );
};

StandardButton.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
  onClick: PropTypes.func,
  height: PropTypes.string,
  href: PropTypes.string,
  target: PropTypes.string,
  width: PropTypes.string,
  fontSize: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  isStatic: PropTypes.bool,
  style: PropTypes.shape(),
};

StandardButton.defaultProps = {
  width: '86px',
  height: '34px',
  fontSize: '14px',
  href: '',
  target: '',
  type: '',
  disabled: false,
  isStatic: false,
  style: {},
  onClick: () => {},
};

export default StandardButton;
