import { TucarTheme } from '@tucar/pit-stop';

export const tucarColors = {
  primary: '#0057B8',
  primaryMono1: '#00336C',
  primaryMono2: '#003F85',
  primaryMono3: '#004B9F',
  primaryMono4: '#0063D2',
  primaryMono5: '#006FEB',
  primaryMono6: '#057BFF',
  secondary: '#6CC8DD',
  secondaryMono1: '#2FB0CD',
  secondaryMono2: '#43B9D3',
  secondaryMono3: '#57C0D8',
  secondaryMono4: '#81D0E2',
  secondaryMono5: '#95D7E7',
  secondaryMono6: '#AADFEB',
  tucarE: '#95D31C',
  tucarEMono1: '#658F13',
  tucarEMono2: '#75A616',
  tucarEMono3: '#85BC19',
  tucarEMono4: '#A3E226',
  tucarEMono5: '#ACE53D',
  tucarEMono6: '#B6E853',
  white: '#FFFFFF',
  whiteTucar: '#F9F9F9',
  blackTucar: '#333333',
  black1: '#1F1F1F',
  black2: '#020202',
  grey1: '#ADADAD',
  grey2: '#C1C1C1',
  grey3: '#D6D6D6',
  grey4: '#EAEAEA',
  grey5: '#F4F4F4',
  yellow1: '#FCF5E8',
  yellow2: '#E1A020',
  red1: '#FBEEED',
  red2: '#DF5A4D',
  green1: '#F4FAE8',
  blue1: '#E6F1FF',
  ...TucarTheme.colors,
};

export const tucarBackground = '#F0F0F3';
export const tucarBlue = tucarColors.primary;
export const tucarRed = '#d32f2f';
export const tucarGreen = '#66bb6a';
export const tucarGrey = '#cccccc';
export const tucarLightBlue = tucarColors.secondary;
export const tucarWhite = tucarColors.whiteTucar;
export const realWhite = tucarColors.white;
export const [uberColor, realBlack] = Array(2).fill('#000000');
export const textGrey = '#888888';
